<template>
  <component :is="layout">
    <div class="fadeInUp" role="main">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <h5>Deactivate account</h5>
          <div class="card border-0 shadow-sm mb-4">
            <div class="card-body">
              <div v-if="organization.owner == user.id">
                <p>
                  You are the sole owner of the following workspaces. By
                  deleting your user account, you will also delete these
                  workspaces and cancel any billing associated with them.
                </p>
                <div class="d-block border rounded p-3 mb-3">
                  <p class="mb-0 font-weight-bold">
                    {{ organization.title }}
                    <span class="ml-3 text-muted">{{
                      organization.domain
                    }}</span>
                  </p>
                </div>
                <router-link
                  :to="{ name: 'account.settings' }"
                  class="btn btn-success btn-block"
                >
                  Return to safety
                </router-link>
              </div>
            </div>
            <div
              class="card-body"
              :class="{ 'border-top': organization.owner == user.id }"
            >
              <p>
                Are you sure you want to delete your account? There’s no coming
                back from this!
              </p>
              <form @submit.prevent="submitForm">
                <div class="form-group">
                  <label for="password_current" class="control-label"
                    >Account password</label
                  >
                  <input
                    id="password_current"
                    v-model="form.password_current"
                    v-validate="'required'"
                    type="password"
                    name="password_current"
                    :class="{
                      'form-control': true,
                      'is-invalid': errors.has('password_current'),
                    }"
                  />
                  <span
                    v-show="errors.has('password_current')"
                    class="invalid-feedback"
                    >{{ errors.first("password_current") }}</span
                  >
                </div>
                <ButtonLoading
                  :loading="loading"
                  :title="'Deactivate account'"
                  :class="'btn btn-danger'"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "DeactivateAccount",
  components: { 
    Default,
    ButtonLoading,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("organization/current").then((response) => {
      next((vm) => {
        vm.organization = response.data;
        if (!vm.organization.billingAddress) {
          vm.organization.billingAddress = {};
        }
        vm.users = vm.organization.users;
      });
    });
  },
  data: function() {
    return {
      loading: false,
      organization: {
        subscription: {},
        plans: [],
        billingAddress: {},
      },
      user: {},
      form: {
        password_current: null,
      },
    };
  },
  methods: {
    ...mapGetters(["getUser"]),
    submitForm: function() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          jsonAPI
            .post("/user/deactivate", this.form)
            .then(async () => {
              await this.$store.dispatch("LOGOUT");
              this.$router.push("/login");
            })
            .catch((error) => {
              this.errors = error.response.data.errors;
              this.loading = false;
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
  mounted() {
    this.user = this.$store.getters.getUser;
  },
};
</script>
